import { useQuery, useQueryClient } from '@tanstack/vue-query'

export const usePaymentAccounts = (currencyId = null) => {
    const { execute } = useApi('/api/payment-accounts', 'GET')
    const paymentAccounts = computed(() => {
        const items = data.value?.data || []

        if (!currencyId) {
            return items
        }

        return items.filter((i) => i.currency_id === currencyId)
    })

    const { data, isLoading, refetch, isFetchedAfterMount } = useQuery({
        queryKey: ['payment-accounts'],
        queryFn: () => execute(),
        staleTime: Infinity,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
    })

    return {
        paymentAccounts,
        refetch,
        isFetchedAfterMount,
        execute,
        isLoading,
    }
}

export const usePaymentAccount = () => {
    const queryClient = useQueryClient()

    const invalidatePaymentAccount = () => {
        queryClient.invalidateQueries({ queryKey: ['payment-accounts'] })
    }

    const updatePaymentAccount = (paymentAccount) => {
        queryClient.setQueryData(['payment-accounts'], (data) => ({
            ...data,
            data: data.data?.map((i) => {
                if (i.id === paymentAccount.id) {
                    return paymentAccount
                }

                return i
            }),
        }))
    }

    return {
        updatePaymentAccount,
        invalidatePaymentAccount,
    }
}
